<template>
  <button
    class="button-icon"
    :class="[
      color,
      size,
      { muted: isMuted, round, square },
      { 'square-gray': color === 'gray-light' },
      { disabled: unclickable },
    ]"
    :disabled="disabled"
    ref="clickBtn"
    v-on:click="animateRipple"
  >
    <slot></slot>
    <transition-group>
      <span
        class="icon-button-effect"
        :ref="'icon-button-effect' + i"
        :key="'icon-button-effect' + i"
        v-for="(val, i) in activeRipples"
        :style="{ top: val.y + 'px', left: val.x + 'px' }"
        v-on:animationend="rippleEnd(i)"
      >
      </span>
    </transition-group>
  </button>
</template>

<script>
export default {
  name: 'HccButtonIcon',
  props: {
    color: {
      type: String,
      default: 'secondary',
    },
    muted: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    round: {
      type: Boolean,
      default: false,
    },
    square: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'sm',
    },
    unclickable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ripples: [],
    };
  },
  computed: {
    activeRipples() {
      return this.ripples.filter(r => r.show);
    },
    isMuted() {
      return this.muted && !this.round && !this.square;
    },
  },
  methods: {
    animateRipple(e) {
      const el = this.$refs.clickBtn;
      const pos = el.getBoundingClientRect();
      this.ripples.push({
        x: e.clientX - pos.left,
        y: e.clientY - pos.top,
        show: true,
      });
      this.$emit('click', e);
    },
    rippleEnd(i) {
      this.ripples[i].show = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~styles/shared/buttons.scss";
@import "~styles/shared/buttons-icons.scss";
</style>

<template>
  <div class="off-canvas-wrapper" :class="{ scrollNone: activeNotification }">
    <site-header v-on:open-notifications="openNotifications" />
    <div
      data-off-canvas
      id="offCanvas"
      class="off-canvas position-left reveal-for-medium"
      v-bind:class="{ 'menu-collapse': collapse, overflow: collapse }"
      v-show="this.windowWidth > 640"
    >
      <div class="site-logo">
        <div class="logo" v-on:click="collapse = !collapse"></div>
      </div>
      <div class="sidebar-wrapper">
        <nav class="sidebar-menu" data-close="offCanvas">
          <ul>
            <li v-for="(item, index) in items()" v-bind:key="index">
              <router-link
                v-bind:to="item.href"
                :key="item.title"
              >
                <div class="icon">
                  <component v-bind:is="item.icon" />
                </div>
                <div class="sidebar-link">
                  <span class="title">{{ item.title }}</span>
                  <hcc-badge
                    v-if="item.new"
                    :text="$t('shared-components.badge.new')"
                    size="sm"
                    color="alert-green"
                    :fill="collapse ? true : false"
                    :customClass="{ 'corner-badge': collapse }"
                  />
                </div>
              </router-link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <div
      data-off-canvas-content
      class="main-content grid-container full"
      :class="{ 'canvas-collapse': collapse, mobile: isMobile }"
    >
      <div class="grid-x">
        <div class="cell">
          <transition name="component-fade" mode="out-in">
            <keep-alive>
              <slot />
            </keep-alive>
          </transition>
        </div>
      </div>
    </div>
    <menu-notification v-if="!isLogin" ref="notificationsMenu" />
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import jwtDecode from 'jwt-decode';
import HccBadge from '@/components/shared/HccBadge/index.vue';
import errorHandler from '@/utils/error.handler';

export default {
  components: {
    SiteHeader: () => import('@/components/Header.vue'),
    MenuNotification: () => import('@/components/MenuNotification.vue'),
    HccBadge,
  },
  mounted() {
    this.collapse = window.screen.width < 1024;
  },
  computed: {
    ...mapGetters({
      accessToken: 'dashboard/getAccessToken',
    }),
    role() {
      try {
        const decoded = jwtDecode(this.accessToken);
        return decoded.role ? decoded.role : '';
      } catch (e) {
        errorHandler.logErrors(e);
        return '';
      }
    },
    collapse: {
      get() {
        return this.$store.state.dashboard.collapse;
      },
      set(value) {
        this.toogleCollapse(value);
      },
    },
    isLogin() {
      return this.$route.name === 'login';
    },
    isOwner() {
      return this.role === 'owner';
    },
    activeNotification() {
      return this.$store.state.notification.toogleMenu;
    },
    messagesUrl() {
      return `${process.env.VUE_APP_REDIRECT_URL}/conversations`;
    },
    teamUrl() {
      return `${process.env.VUE_APP_REDIRECT_URL}/team`;
    },
    dataBankUrl() {
      return `${process.env.VUE_APP_REDIRECT_URL}/data-bank`;
    },
  },
  methods: {
    ...mapMutations({
      toogleCollapse: 'dashboard/toogleCollapse',
    }),
    openNotifications() {
      this.$refs.notificationsMenu.displayMenu();
    },
    items() {
      const itemsTotal = [
        {
          title: this.$t('app.dashboard'),
          href: '/dashboard',
          icon: 'view-dashboard-icon',
          new: false,
        },
        {
          title: this.$t('app.send-templates'),
          href: '/send-templates',
          icon: 'whatsapp',
          new: false,
        },
        {
          title: this.$t('app.campaigns'),
          href: '/campaigns',
          icon: 'bullhorn-icon',
          new: false,
        },
        {
          title: this.$t('app.messages'),
          href: '/messages',
          icon: 'message-icon',
          new: false,
        },
        {
          title: this.$t('app.team'),
          href: '/team',
          icon: 'account-group',
          new: false,
        },
      ];

      if (this.role === 'owner' || this.role === 'super') {
        itemsTotal.push({
          title: this.$t('app.users'),
          href: '/users',
          icon: 'account-multiple-icon',
          new: false,
        });
      }
      if (this.role === 'owner') {
        itemsTotal.push({
          title: this.$t('app.company'),
          href: '/company',
          icon: 'briefcase-icon',
          new: false,
        });
      }
      itemsTotal.push({
        title: this.$t('app.store'),
        href: '/store',
        icon: 'store-icon',
        new: false,
      });
      return itemsTotal;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~styles/components/_site-navigation.scss";
</style>

/* eslint-disable import/prefer-default-export */
export const dashboard = {
  namespaced: true,
  state: {
    collapse: false,
    loader: {
      conversationsDataCards: true,
      conversationsByChannel: true,
      conversationsReceivedVsAttended: true,
      conversationsByCategory: true,
      conversationsStatuses: true,
      messagesDataCards: true,
      messagesAgentVsClient: true,
      messagesPerHour: true,
      agentsKpi: true,
      agentsTotal: true,
      agentsByCategories: true,
      clientsDataCards: true,
      conversationIvr: true,
    },
    accessToken: null,
  },
  getters: {
    getAccessToken(state) {
      return state.accessToken;
    },
  },
  mutations: {
    toogleCollapse(state, payload) {
      state.collapse = payload;
    },
    enableLoader(state, id) {
      state.loader[id] = true;
    },
    disableLoader(state, id) {
      state.loader[id] = false;
    },
    updateAccessToken(state, data) {
      state.accessToken = data;
    },
  },
};

/* eslint-disable import/prefer-default-export */

export const getters = {
  activeConversation(state) {
    return state.conversations;
  },
  chatIsLoading(state) {
    return state.conversationStates.loading ?? false;
  },
  chatFirstLoad(state) {
    return state.conversationStates.firstLoad ?? true;
  },
  chatTopReached(state) {
    return state.conversationStates.topReached ?? false;
  },
  chatOldestMessageId(state) {
    if (state.messages.length > 0) {
      const oldestMessage = state.messages[0];
      return oldestMessage.id;
    }
    return null;
  },
};

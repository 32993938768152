/* eslint-disable import/prefer-default-export */
import Vue from 'vue';
import moment from 'moment-timezone';

export const mutations = {
  setActiveClient(state, clientId) {
    state.activeClientId = clientId;
  },
  updateConversation(state, payload) {
    state.conversations = payload;
  },
  removeConversationState(state) {
    state.messages = [];
    state.conversationStates = {};
  },
  addMessageToConversation(state, newMessages) {
    if (state.messages.length === 0 || newMessages.length > 1) {
      state.messages = newMessages.concat(state.messages || []);
    } else if (newMessages.length === 1) {
      const [message = {}] = newMessages;
      const indexMessage = (state.messages || []).findIndex(({ id }) => id === message.id);
      if (indexMessage !== -1) {
        Vue.set(state.messages, indexMessage, message);
      } else {
        const msgTotal = state.messages.length;
        const conversationMessages = state.messages;
        const newestDate = conversationMessages[msgTotal - 1].createdAt ?? null;

        if (moment(message.createdAt).isAfter(newestDate)) {
          state.messages.push(message);
        } else {
          const oldestDate = conversationMessages[0].createdAt ?? null;

          if (moment(message.createdAt).isBefore(oldestDate)) {
            state.messages.unshift(message);
          } else {
            const newIndex = conversationMessages.findIndex(
              ({ createdAt }) => moment(message.createdAt).isBefore(createdAt),
            );

            state.messages.splice(newIndex, 0, message);
          }
        }
      }
    }
  },
  updateConversationFirstLoadState(state, firstLoad) {
    state.conversationStates = { ...state.conversationStates, firstLoad };
  },
  updateConversationLoadingState(state, loading) {
    state.conversationStates = { ...state.conversationStates, loading };
  },
  updateConversationTopReachedState(state, topReached) {
    state.conversationStates = { ...state.conversationStates, topReached };
  },
};

import Vue from 'vue';
import VueApollo from 'vue-apollo';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { split, ApolloLink } from 'apollo-link';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import { createUploadLink } from 'apollo-upload-client';
import errorHandler from '@/utils/error.handler';
import introspectionQueryResultData from '../possibleTypes.json';

Vue.use(VueApollo);

const AUTH_TOKEN = 'hey-access-token';
const httpEndpoint = process.env.VUE_APP_GRAPHQL_HTTP || 'http://localhost:4040/api/v1/graphql';
const wsEndpoint = process.env.VUE_APP_GRAPHQL_WS || 'ws://localhost:4040/subscriptions';

// Call this in the Vue app file
export function createProvider() {
  // HTTP connection to the API
  const httpLink = createUploadLink({
    // You should use an absolute URL here
    uri: httpEndpoint,
  });

  const middlewareLink = new ApolloLink((operation, forward) => {
    const token = localStorage.getItem(AUTH_TOKEN);
    operation.setContext({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return forward(operation);
  });

  // Cache implementation
  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData,
  });
  const cache = new InMemoryCache({ fragmentMatcher });

  const wsLink = new WebSocketLink({
    uri: wsEndpoint,
    options: {
      lazy: true,
      reconnect: true,
      connectionParams: () => {
        const token = localStorage.getItem(AUTH_TOKEN);
        return { token };
      },
    },
  });

  const link = split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    middlewareLink.concat(httpLink),
  );

  // Create the apollo client
  const apolloClient = new ApolloClient({
    link,
    cache,
    connectToDevTools: true,
  });

  // Create vue apollo provider
  return new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        // fetchPolicy: 'cache-and-network',
      },
    },
    errorHandler(error) {
      errorHandler.logErrors(error);
    },
  });
}

// Manually call this when user log in
export async function onLogin(apolloClient, token) {
  if (typeof localStorage !== 'undefined' && token) {
    localStorage.setItem(AUTH_TOKEN, token);
  }

  try {
    await apolloClient.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    errorHandler.logErrors(e);
  }
}

// Manually call this when user log out
export async function onLogout(apolloClient) {
  try {
    await apolloClient.resetStore();
    localStorage.removeItem('currentCompany');
    localStorage.removeItem('currentCampaign');
    localStorage.removeItem('currentChannel');
    localStorage.removeItem('currentAgent');
  } catch (e) {
    errorHandler.logErrors(e);
  } finally {
    if (typeof localStorage !== 'undefined') {
      localStorage.removeItem(AUTH_TOKEN);
    }
  }
}

// Get Token
export function getToken(tokenName = AUTH_TOKEN) {
  return localStorage.getItem(tokenName);
}

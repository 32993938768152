const chartColors = [
  '#F56565',
  '#ED8936',
  '#ECC94B',
  '#48BB78',
  '#38B2AC',
  '#4299E1',
  '#667EEA',
  '#9F7AEA',
  '#FC8181',
  '#F6AD55',
  '#F6E05E',
  '#68D391',
  '#4FD1C5',
  '#63B3ED',
  '#7F9CF5',
  '#B794F4',
  '#FEB2B2',
  '#FBD38D',
  '#FAF089',
  '#9AE6B4',
  '#81E6D9',
  '#90CDF4',
  '#A3BFFA',
  '#D6BCFA',
  '#FED7D7',
  '#FEEBC8',
  '#FEFCBF',
  '#C6F6D5',
  '#B2F5EA',
  '#BEE3F8',
  '#C3DAFE',
  '#E9D8FD',
];

function getDisplayableFormat(format) {
  switch (format) {
    case 'YYYY-MM-DD':
      return 'DD MMM YYYY';
    case 'YYYY-MM':
      return 'MMM YYYY';
    case 'YYYY':
      return 'YYYY';
    case 'HH':
      return 'hh:mm A';
    case 'E':
      return 'ddd';
    case 'MM':
      return 'MMM';
    default:
      return format;
  }
}

function getDataNotification(item) {
  const data = Object.assign({}, item);
  let msg = '';
  let title = '';
  if (item.action.type === 'show-detail') {
    msg = `Numeros enviados: ${item.message}`;
    title = 'template';
    data.message = msg;
  } else {
    title = 'report';
  }

  data.title = title;
  return data;
}

function validPassword(password) {
  const isValid = new RegExp('^(?=.*?[0-9])(?=.*?[A-Z])(?=.*[a-z]).{8,}$');
  return isValid.test(password);
}

function getImageUrlInformation(imageUrl) {
  return fetch(imageUrl)
    .then(response => response.blob())
    .then((blob) => {
      const data = blob;
      switch (data.type) {
        case 'image/png':
          data.ext = 'png';
          break;
        case 'image/jpeg':
          data.ext = 'jpeg';
          break;
        default:
          break;
      }
      return data;
    });
}

function createNameCanonical(name) {
  let nameCanonical = name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  nameCanonical = name.toLowerCase().replace(/[\s*())]/g, '');
  return `@${nameCanonical}`;
}

async function getDataImage(dataProduct) {
  const data = dataProduct;
  data.image = await getImageUrlInformation(data.imageURL);
  return {
    name: `${data.nombre}.${data.image.ext}`,
    url: data.imageURL,
    size: data.image.size,
    ext: data.image.ext,
    type: 'image/*',
  };
}

function hours() {
  const time = [];
  for (let i = 1; i < 24; i += 1) {
    time.push({ id: `${i}`, name: `${i}:00` });
  }
  time.push({ id: '0', name: '24:00' });
  return time;
}

function clearString(string) {
  const accents = 'ÀÁÂÃÄÅàáâãäåßÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž';
  const accentsOut = 'AAAAAAaaaaaaBOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz';
  const nstring = string.split('');
  nstring.forEach((letter, index) => {
    const i = accents.indexOf(letter);
    if (i !== -1) {
      nstring[index] = accentsOut[i];
    }
  });
  return nstring.join('');
}

export {
  hours,
  getDisplayableFormat,
  chartColors,
  getDataNotification,
  validPassword,
  getImageUrlInformation,
  createNameCanonical,
  getDataImage,
  clearString,
};

<template>
  <div class="confirmation__buttons">
    <hcc-button
      v-if="showCancel"
      @click="handleCancel"
      size="sm"
      variant="outline"
      color="muted"
      >{{ $t("shared-components.confirmation.cancel") }}</hcc-button
    >
    <hcc-button
      @click="handleConfirm"
      size="sm"
      class="confirmation__buttons--button"
      :color="getButtonColor"
      >{{ $t("shared-components.confirmation.confirm") }}</hcc-button
    >
  </div>
</template>

<script>
import HccButton from '../HccButton/index.vue';

export default {
  components: {
    HccButton,
  },
  props: {
    variant: {
      type: String,
      default: 'alert',
    },
    handleConfirm: {
      type: Function,
    },
    handleCancel: {
      type: Function,
    },
    showCancel: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    getButtonColor() {
      let color = 'secondary';

      if (this.variant === 'error') {
        color = 'alert';
      } else if (this.variant === 'info') {
        color = 'primary';
      }
      return color;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~styles/shared/confirmation.scss";
</style>

<template>
  <div>
    <transition
      name="custom-box-transition"
      enter-active-class="slideInRight"
      leave-active-class="fadeOut"
    >
      <chat-box
        v-if="isOpen"
        :title="chatBoxTitle"
        :showFooter="showAgentChat"
        :activeChat="activeChat"
        @close="closeBox"
        @minimize="minimizeBox"
        @go-back="goBack"
        @send-message="sendMessage"
        style="animation-duration: 0.7s"
      >
        <overlay-loader :loading="loading" />
        <agent-list
          v-if="showAgentList && !loading"
          :agents="getAgents"
          :selectedCampaign="selectedCampaign"
          @select-agent="openAgentChat"
          @change-campaign="changeCampaign"
          @search="searchAgents"
        />
        <agent-chat
          v-if="showAgentChat"
          :agent="selectedAgent"
        />
      </chat-box>
    </transition>
    <button
      v-if="showButton"
      class="chat-button"
      :class="{ 'chat-button__message': showAgentList }"
      v-tooltip.left="{
        content: $t('chat-button.contact-agent'),
        visible: !showAgentList,
      }"
      @click="openBox"
    >
      <span v-if="showAgentList">{{ $t("chat-button.messages") }}</span>
      <forum-icon
        class="chat-button__icon"
        :class="{ 'chat-button__message-icon': showAgentList }"
      />
    </button>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import executeQuery from '@/utils/gql-api';
import queryAgents from '@/graphql/queries/users/listCompanyTeam.gql';
import sendInternalMessageGql from '@/graphql/mutations/sendInternalMessage.gql';
import internalChat from '@/graphql/suscriptions/internalChat.gql';
import OverlayLoader from '@/components/loaders/OverlayLoader.vue';
import ChatBox from './ChatBox.vue';
import AgentList from './AgentList.vue';
import AgentChat from './AgentChat.vue';


export default {
  components: {
    ChatBox,
    AgentList,
    AgentChat,
    OverlayLoader,
  },
  data() {
    return {
      showAgentList: false,
      showAgentChat: false,
      selectedAgent: null,
      loading: false,
      activeChat: false,
      agents: [],
      selectedCampaign: null,
      search: '',
    };
  },
  computed: {
    ...mapGetters({
      userInfo: 'shared/userInfo',
      campaign: 'setting/getCurrentCampaign',
      commonCompany: 'shared/getCurrentCompany',
    }),
    onlineAgents() {
      return this.$t('chat-button.online-agents');
    },
    chatBoxTitle() {
      return this.showAgentChat ? this.selectedAgent.name : this.onlineAgents;
    },
    isOpen() {
      return this.showAgentList || this.showAgentChat;
    },
    emptyList() {
      return this.agents.length === 0;
    },
    showButton() {
      return !this.isOpen;
    },
    filteredAgents() {
      return this.agents.filter(agent => agent.name.includes(this.search.toLowerCase()));
    },
    getAgents() {
      return this.search.length > 0 ? this.filteredAgents : this.agents;
    },
    currentCompanyId() {
      return this.userInfo.role === 'owner' ? this.commonCompany.id : this.userInfo.company;
    },
    allSeccion() {
      if (this.selectedCampaign.id !== 'all') {
        return { ...this.selectedCampaign, type: 'campaign' };
      }
      return {
        name: this.$t('chat-button.general'),
        id: 'all',
        type: 'company',
      };
    },
  },
  methods: {
    ...mapMutations({
      addMessage: 'internalChat/addMessage',
    }),
    openBox() {
      this.showAgentList = true;
      this.fetchAgents();
    },
    closeBox() {
      this.showAgentList = false;
      this.showAgentChat = false;
      this.activeChat = false;
    },
    minimizeBox() {
      this.showAgentList = false;
      this.showAgentChat = false;
    },
    openAgentChat(agent) {
      this.showAgentChat = true;
      this.showAgentList = false;
      this.selectedAgent = this.setAgent(agent);
    },
    setAgent(agent) {
      if (agent === 'all') {
        return {
          name: this.allSeccion.name,
          id: this.allSeccion.id,
          type: this.allSeccion.type,
        };
      }
      return { ...agent, type: 'agent' };
    },
    goBack() {
      this.showAgentChat = false;
      this.showAgentList = true;
    },
    changeCampaign(campaign) {
      this.selectedCampaign = campaign;
      this.fetchAgents();
    },
    searchAgents(value) {
      this.search = value;
    },
    async sendMessage(message) {
      const params = this.prepareQuery();

      await this.$apollo.mutate({
        mutation: sendInternalMessageGql,
        variables: {
          from: this.userInfo.id,
          text: message,
          ...params,
        },
      })
        .then(() => {
          this.activeChat = true;

          this.addMessage({
            text: message,
            from: this.userInfo.id,
            to: this.selectedAgent.id,
            createdAt: new Date().toISOString(),
            received: false,
          });
        });
    },
    async fetchAgents() {
      this.loading = true;
      let varaibles = { companyId: this.currentCompanyId };

      if (this.selectedCampaign.id && this.selectedCampaign.id !== 'all') {
        varaibles = { campaignId: this.selectedCampaign.id };
      }

      const result = await executeQuery(
        'companyTeam', queryAgents, varaibles, false,
      );
      this.agents = result;
      this.loading = false;
    },
    prepareQuery() {
      if (this.selectedAgent.type === 'agent') {
        return { to: this.selectedAgent.id };
      }

      if (this.selectedAgent.type === 'campaign') {
        return { campaign: this.selectedCampaign.id };
      }

      return { company: this.currentCompanyId };
    },
  },
  created() {
    this.$apollo.subscriptions.internalConversation.skip = true;
  },
  mounted() {
    setTimeout(() => {
      this.$apollo.subscriptions.internalConversation.skip = false;
    }, 2000);

    this.selectedCampaign = this.campaign;
  },
  apollo: {
    $subscribe: {
      internalConversation: {
        query: internalChat,
        result({ data }) {
          const { internalConversation: chat } = data;

          this.addMessage({
            text: chat.text,
            from: chat.from.id,
            to: chat.to.id,
            createdAt: chat.createdAt,
            received: true,
          });
        },
        error(err) {
          this.networkErr(err);
          this.$toasted.global.error(this.$t('alerts.unexpectedError'));
        },
      },
    },
  },
};
</script>

<style scoped lang="scss">
@import "~styles/components/chatButton/_chat-button.scss";
</style>

<template>
  <div class="confirmation__header">
    <div class="confirmation__header--title" :class="variant">
      {{ title }}
    </div>
    <hcc-button-icon
      v-if="variant === 'error'"
      round
      class="confirmation__header--icon"
      color="alert"
      unclickable
    >
      <delete-icon />
    </hcc-button-icon>
    <hcc-button-icon
      v-else-if="variant === 'info'"
      round
      class="confirmation__header--icon"
      color="primary"
      unclickable
    >
      <information-variant-icon />
    </hcc-button-icon>
    <hcc-button-icon
      v-else
      round
      class="confirmation__header--icon"
      color="secondary"
      unclickable
    >
      <exclamation-thick-icon />
    </hcc-button-icon>
  </div>
</template>

<script>
import HccButtonIcon from '../HccButtonIcon/index.vue';

export default {
  components: {
    HccButtonIcon,
  },
  props: {
    title: {
      type: String,
    },
    variant: {
      type: String,
    },
  },

};
</script>

<style scoped lang="scss">
@import "~styles/shared/confirmation.scss";
</style>

<template>
  <div>
    <div class="chat-box__footer">
      <hcc-textarea
        ref="chatInput"
        v-model="message"
        name="chatInput"
        :rows="1"
        :columns="65"
        :maxLength="1000"
        :placeholder="$t('chat-button.write-message')"
        customClass="chat-box__input"
        customContainerClass="chat-box__input"
        @keydown.enter.exact.prevent="send"
      />
      <button class="chat-box__send-button" @click="send">
        <send-icon />
      </button>
    </div>
  </div>
</template>


<script>
import HccTextarea from '@/components/shared/HccTextarea/index.vue';


export default {
  components: {
    HccTextarea,
  },
  data() {
    return {
      message: '',
    };
  },
  methods: {
    send() {
      this.$emit('send', this.message);
      this.message = '';
      this.focus();
    },
    focus() {
      this.$refs.chatInput.focus();
    },
  },
  mounted() {
    this.focus();
  },
};
</script>
<style scoped lang="scss">
@import "~styles/components/chatButton/_chat-button.scss";
</style>

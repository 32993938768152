import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import SecureLS from 'secure-ls';
import {
  dashboard,
  template,
  notification,
  setting,
  databank,
  shared,
  chat,
  reports,
  shop,
  product,
  internalChat,
} from './modules';

const ls = new SecureLS({ isCompression: false });

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    dashboard,
    template,
    notification,
    setting,
    databank,
    shared,
    chat,
    reports,
    shop,
    product,
    internalChat,
  },
  state: {
    windowWidth: window.innerWidth,
  },
  mutations: {
    setWindowWidth(state, value) {
      state.windowWidth = value;
    },
  },
  plugins: [
    createPersistedState({
      paths: ['dashboard', 'shared'],
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key),
      },
    }),
  ],
});

/* eslint-disable import/prefer-default-export */
import Vue from 'vue';
import reportsType from '@/graphql/queries/getReportsType.gql';
import executeQuery from '@/utils/gql-api';
import errorHandler from '@/utils/error.handler';

export const reports = {
  namespaced: true,
  state: {
    types: [],
    reports: [],
    schedule: [],
  },
  actions: {
    fetchTypes({ commit }) {
      executeQuery('reportsTypes', reportsType)
        .then(result => commit('setTypes', result))
        .catch((err) => {
          errorHandler.logErrors(err);
        });
    },
  },
  mutations: {
    addReport(state, payload) {
      const index = state.reports.findIndex(({ jobId }) => jobId === payload.jobId);
      if (index !== -1) {
        Vue.set(state.reports, index, payload);
      } else {
        state.reports.unshift(payload);
      }
    },
    addSchedule(state, payload) {
      state.schedule.unshift(payload);
    },
    removeSchedule(state, reportKey) {
      const index = state.schedule.findIndex(({ key }) => reportKey === key);
      if (index !== -1) {
        state.schedule.splice(index, 1);
      }
    },
    setReports(state, payload) {
      state.reports = payload;
    },
    setSchedule(state, payload) {
      state.schedule = payload;
    },
    setTypes(state, payload) {
      state.types = payload;
    },
  },
};

<template>
  <div class="agent-list">
    <agent-list-header
      :selectedCampaign="selectedCampaign"
      @change-campaign="changeCampaign"
      @search="searchValue"
      @all-agents="selectAgent('all')"
    />
    <empty-list v-if="isEmpty" />
    <div
      class="agent-list__item"
      v-for="(agent, i) in agents"
      :key="agent.name + i"
      @click="selectAgent(agent)"
    >
      <div class="agent-list__info">
        <div class="agent-list__photo">
          <span class="agent-list__acronym"
            >{{ profileAcronym(agent.name) }}
          </span>
          <status-dot class="agent-list__status" />
        </div>
        <div class="agent-list__agent-info">
          <span class="agent-list__name">
            {{ agent.name }}
          </span>
          <span class="agent-list__email">
            {{ agent.email }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StatusDot from '@/components/shared/HccStatusDot/index.vue';
import AgentListHeader from './AgentListHeader.vue';
import EmptyList from './EmptyList.vue';

export default {
  components: {
    StatusDot,
    AgentListHeader,
    EmptyList,
  },
  props: {
    agents: {
      type: Array,
      required: true,
    },
    selectedCampaign: {
      type: [Object, null],
    },
  },
  computed: {
    isEmpty() {
      return this.agents.length === 0;
    },
  },
  methods: {
    profileAcronym(name) {
      return name.substr(0, 1);
    },
    selectAgent(agent) {
      this.$emit('select-agent', agent);
    },
    changeCampaign(campaign) {
      this.$emit('change-campaign', campaign);
    },
    searchValue(value) {
      this.$emit('search', value);
    },
  },
};
</script>

<style scoped lang="scss">
@import "~styles/components/chatButton/_chat-button.scss";
</style>

/* eslint-disable import/prefer-default-export */
import Vue from 'vue';
import dataBankActions from './actions/dataBankActions';

const setDefaulState = () => ({
  databank: {
    text: [],
    image: null,
    document: null,
    video: null,
    location: null,
  },
  templates: [],
});

export const databank = {
  namespaced: true,
  state: setDefaulState(),
  getters: {
    getDataBank(state) {
      return state.databank;
    },
    getTabSection(state) {
      return type => state.databank[type];
    },
    getTemplates(state) {
      return state.templates;
    },
  },
  mutations: {
    setDataBank(state, { type, payload }) {
      Vue.set(state.databank, type, payload);
    },
    updateDataBank(state, { payload, index }) {
      Vue.set(state.databank[payload.type], index, payload);
    },
    deleteDataBank(state, { index, type }) {
      state.databank[type].splice(index, 1);
    },
    setTemplates(state, payload) {
      state.templates = payload;
    },
    addTemplate(state, payload) {
      state.templates.push(payload);
    },
    updateTemplate(state, { id, status }) {
      const index = state.templates.findIndex(temp => temp.id === id);
      if (index !== -1) {
        const template = state.templates[index];
        Vue.set(state.templates, index, { ...template, status });
      }
    },
    clear(state) {
      Object.assign(state, setDefaulState());
    },
  },
  actions: {
    ...dataBankActions,
  },
};

<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
    <chat-button v-if="showChatButton" />
  </div>
</template>

<script>
import { auth } from '@/utils/firebase';
import { mapMutations, mapState, mapGetters } from 'vuex';
import ChatButton from '@/components/chatButton/index.vue';

const defaultLayout = 'without-layout';

export default {
  components: {
    ChatButton,
  },
  computed: {
    ...mapState({
      user: state => state.shared.user,
    }),
    ...mapGetters({
      userInfo: 'shared/userInfo',
      userRole: 'shared/getUserRole',
    }),
    layout() {
      return (this.$route.meta.layout || defaultLayout);
    },
    showChatButton() {
      return ['super', 'admin', 'owner'].includes(this.userRole)
        && this.$route.meta.forAuth;
    },
  },
  mounted() {
    if (this.user && this.user.id) {
      auth.signInAnonymously();
    }
    window.addEventListener('resize', this.onResize);
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    ...mapMutations(['setWindowWidth']),
    onResize() {
      this.setWindowWidth(window.innerWidth);
    },
  },
};
</script>

<style lang="scss">
@import "~styles/_app.scss";
</style>

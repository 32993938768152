import moment from 'moment-timezone';
import { db, storage } from '@/utils/firebase';
import errorHandler from '@/utils/error.handler';
import { createNameCanonical } from '@/utils/helper';

function parseSchedule(schedules) {
  return schedules.reduce((obj, { start, end, weekday }) => {
    const momentStart = moment.unix(start / 1000).toISOString();
    const momentEnd = moment.unix(end / 1000).toISOString();
    if (obj[weekday]) {
      return {
        ...obj,
        [weekday]: {
          start: momentStart,
          end: momentEnd,
        },
      };
    }
    return {
      ...obj,
      [weekday]: {
        start: momentStart,
        end: momentEnd,
      },
    };
  }, {});
}

export default {
  namespaced: true,
  state: {
    item: {},
    items: [],
    session: window.localStorage.getItem('SELLIA-SESSION'),
    currentCar: {},
    statistics: {},
    channelsStatics: [],
  },
  mutations: {
    setChannelsStatics(state, value) {
      state.channelsStatics = value;
    },
    setItems(state, value) {
      state.items = value;
    },
    setItem(state, value) {
      state.item = value;
    },
    setCurrentCar(state, value) {
      state.currentCar = value;
    },
    createdOrUpdated(state, value) {
      const index = state.items.findIndex(({ id }) => value.id === id);
      if (index !== -1) {
        state.items.splice(index, 1, { ...value.data(), id: value.id });
      }
    },
    removeItem(state, elementId) {
      const index = state.items.findIndex(({ id }) => elementId === id);
      if (index !== -1) {
        state.items.splice(index, 1);
      }
    },
    setStatistic(state, value) {
      state.statistics = value;
    },
    setSession(state, value) {
      state.session = value;
    },
  },
  actions: {
    async getStoreOfUser({ commit }, [column, operator, value]) {
      const snap = await db.collection('stores')
        .where(column, operator, value)
        .get();

      if (!snap.empty) {
        commit('setItems', snap.docs.map(doc => ({ ...doc.data(), id: doc.id })));
      } else {
        commit('setItems', []);
      }
    },
    async clearStoreOfUser({ commit }) {
      commit('setItems', []);
    },
    async getById({ commit }, id) {
      if (id) {
        const snap = await db.collection('stores')
          .doc(id)
          .get();

        if (snap) {
          const dataStore = snap.data();
          const company = await db.collection('companies')
            .doc(dataStore.heydata.company)
            .get();

          const canales = company.exists ? company.data().channels : [];
          commit('setItem', { ...dataStore, id: snap.id, canales });
        } else {
          commit('setItem', null);
        }
      } else {
        commit('setItem', null);
      }
    },
    async save({ commit }, params) {
      const {
        id,
        file,
        prevId,
        schedules,
        messages,
        ...newStore
      } = params;
      let horario = newStore.horario || {};
      if (schedules) {
        horario = parseSchedule(schedules);
      }
      let messageOutOfService = newStore.messageOutOfService || {};
      if (messages) {
        messageOutOfService = messages.find(({ name: nameMg }) => nameMg === 'messageOutOfService');
      }

      let data = {
        ...newStore,
        horario,
        messageOutOfService: messageOutOfService ?? '',
        lastModification: new Date(),
      };
      data['name-id-canonical'] = createNameCanonical(newStore.nombre);

      if (file) {
        const storageRef = storage.ref();
        const spaceRef = storageRef.child(`${id}/images/perfil/perfil.jpg`);
        const snapshot = await spaceRef.put(file);
        const imageURL = await snapshot.ref.getDownloadURL();
        data.imageURL = imageURL;
      }

      if (prevId) {
        const prevSnap = await db.collection('stores')
          .doc(prevId)
          .get();

        data = { ...prevSnap.data(), ...data };
        if (prevId !== id) {
          const { docs } = await db
            .collection('products')
            .where('storeId', '==', prevId)
            .get();

          await docs.reduce((promise, doc) => {
            const { store, ...product } = doc.data();
            return promise.then(() => db.collection('products').add({ ...product, storeId: id }));
          }, Promise.resolve());
        }
      }

      let lastId;
      if (id) {
        await db.collection('stores').doc(id).set(data);
        lastId = id;
      } else {
        const snap = await db.collection('stores').add(data);
        lastId = snap.id;
      }

      const lastDoc = await db.collection('stores').doc(lastId).get();
      commit('createdOrUpdated', lastDoc);
    },
    async saveEcommerce({ commit }, data) {
      const ecommerceData = {
        ...data,
        lastModification: new Date(),
      };
      let lastId = ecommerceData.id;
      ecommerceData['name-id-canonical'] = createNameCanonical(ecommerceData.nombre);
      if (ecommerceData.id) {
        await db.collection('stores').doc(ecommerceData.id).set(ecommerceData);
      } else {
        const snap = await db.collection('stores').add(ecommerceData);
        lastId = snap.id;
      }
      const lastDoc = await db.collection('stores').doc(lastId).get();
      commit('createdOrUpdated', lastDoc);
    },
    async update({ commit }, { id, ...data }) {
      try {
        const data2Update = { ...data };
        if (data2Update.schedules) {
          data2Update.horario = parseSchedule(data2Update.schedules);
          delete data2Update.schedules;
        }
        if (data2Update.messages) {
          const messageOutOfService = data2Update.messages.find(({ name: nameMg }) => nameMg === 'messageOutOfService');
          data2Update.messageOutOfService = messageOutOfService ?? '';
          delete data2Update.messages;
        }
        await db.collection('stores').doc(id).update(data2Update);

        const lastDoc = await db.collection('stores').doc(id).get();
        commit('createdOrUpdated', lastDoc);
      } catch (err) {
        errorHandler.logErrors(err);
      }
    },
    async remove({ commit }, id) {
      await db.collection('stores').doc(id).delete();
      const snapItems = await db.collection('products')
        .where('storeId', '==', id)
        .get();
      const batch = db.batch();
      snapItems.forEach(doc => batch.delete(doc.ref));
      await batch.commit();

      commit('removeItem', id);
    },
    async newOrUpdateLead({ commit }, { id, ...payload }) {
      if (id) {
        await db.collection('leads').doc(id).update(payload);
        const doc = await db.collection('leads').doc(id).get();
        commit('setCurrentCar', { ...doc.data(), id: doc.id });
      } else {
        const snap = await db.collection('leads').add(payload);
        const doc = await snap.get();
        commit('setCurrentCar', { ...doc.data(), id: doc.id });
      }
    },
    async leadPendingCar({ commit }, { user, storeId }) {
      commit('setCurrentCar', {});
      const { docs } = await db.collection('leads')
        .where('storeId', '==', storeId)
        .where('userId', '==', user)
        .limit(1)
        .get();
      if (docs.length > 0) {
        commit('setCurrentCar', { ...docs[0].data(), id: docs[0].id });
      } else {
        commit('setCurrentCar', null);
      }
    },
    async getStatistics({ commit }, { campaigns, endDate, startDate }) {
      try {
        const channels = [];
        const data = await campaigns.reduce(async (promise, storeId) => {
          let {
            leads = 0,
            sales = 0,
            profits = 0,
            sessions = 0,
            salesAmount = 0,
            cantidad = 0,
            visitorsQr = 0,
          } = await promise;
          let sessionsQuery = db.collection('sessions')
            .where('storeId', '==', storeId);
          let visitorsQuery = db.collection('sessions')
            .where('storeId', '==', storeId)
            .where('userId', '==', 'QR');
          let salesQuery = db.collection('sales')
            .where('storeId', '==', storeId);
          let leadsQuery = db.collection('leads')
            .where('storeId', '==', storeId);
          if (endDate) {
            const endRange = moment(endDate).endOf('day').toDate();
            sessionsQuery = sessionsQuery.where('createdAt', '<=', endRange);
            visitorsQuery = visitorsQuery.where('createdAt', '<=', endRange);
            salesQuery = salesQuery.where('createdAt', '<=', endRange);
            leadsQuery = leadsQuery.where('createdAt', '<=', endRange);
          }
          if (startDate) {
            const startRange = moment(startDate).startOf('day').toDate();
            sessionsQuery = sessionsQuery.where('createdAt', '>=', startRange);
            visitorsQuery = visitorsQuery.where('createdAt', '>=', startRange);
            salesQuery = salesQuery.where('createdAt', '>=', startRange);
            leadsQuery = leadsQuery.where('createdAt', '>=', startRange);
          }

          leadsQuery = await leadsQuery.get();
          leads += leadsQuery.size;
          sessionsQuery = await sessionsQuery.get();
          sessions += sessionsQuery.size;
          visitorsQuery = await visitorsQuery.get();
          visitorsQr += visitorsQuery.size;
          salesQuery = await salesQuery.get();
          sales += salesQuery.size;
          const {
            total: totalRow,
            profit: profitRow,
            cantidad: cantidadRow,
          } = salesQuery.docs.reduce((prev, doc) => {
            const { car, channel } = doc.data();
            channels.push(channel);
            return {
              total: prev.total + car.reduce((t, { precio }) => t + precio, 0),
              profit: prev.profit + car.reduce(
                (t, { precio, compra = 0 }) => t + (precio - compra),
                0,
              ),
              cantidad: prev.cantidad + car.reduce((t, { quantity }) => t + quantity, 0),
            };
          }, { total: 0, profit: 0, cantidad: 0 });

          profits += profitRow;
          salesAmount += totalRow;
          cantidad += cantidadRow;
          return {
            leads,
            sales,
            profits,
            sessions,
            salesAmount,
            cantidad,
            visitorsQr,
          };
        }, Promise.resolve({}));

        commit('setStatistic', data);
        commit('setChannelsStatics', channels);
      } catch (err) {
        errorHandler.logErrors(err);
      }
    },
    async getChannels({ commit }, { campaigns, endDate, startDate }) {
      let channels = await Promise.all(
        await campaigns.map(async (storeId) => {
          let salesQuery = db.collection('sales')
            .where('storeId', '==', storeId);
          if (endDate) {
            const endRange = moment(endDate).endOf('day').toDate();
            salesQuery = salesQuery.where('createdAt', '<=', endRange);
          }
          if (startDate) {
            const startRange = moment(startDate).startOf('day').toDate();
            salesQuery = salesQuery.where('createdAt', '>=', startRange);
          }
          salesQuery = await salesQuery.get();
          const data = await salesQuery.docs.map(doc => doc.data().channel);
          return data[0];
        }),
      );
      channels = await channels.filter(id => id !== undefined);
      commit('setChannelsStatics', channels);
    },
    removeSession({ commit }) {
      commit('setSession', null);
      window.localStorage.removeItem('SELLIA-SESSION');
    },
    async findSession({ commit }, storeId) {
      commit('setSession', null);
      try {
        let currentSession = window.localStorage.getItem('SELLIA-SESSION');
        if (!currentSession) {
          const newSession = await db.collection('sessions')
            .add({
              storeId,
              userId: 'QR',
              createdAt: new Date(),
            });
          currentSession = newSession.id;
          window.localStorage.setItem('SELLIA-SESSION', currentSession);
        }

        commit('setSession', currentSession);
      } catch (err) {
        errorHandler.logErrors(err);
      }
    },
    async updateOrCreateChannels(actions, { id, channels }) {
      try {
        const companyQuery = db.collection('companies').doc(id);

        const currentCompany = await companyQuery.get();
        const currentChannels = currentCompany.exists ? currentCompany.data().channels : [];
        const newChannels = channels.map(({ channelType, extras }) => {
          const type = channelType.key;
          let username;
          if (type === 'telegram') {
            username = extras.name;
          } else if (type === 'whatsapp') {
            username = extras.name;
          } else if (type === 'messenger') {
            username = extras.fanPageId;
          }
          return { type, username };
        }).filter(({ username }) => username);

        newChannels.forEach(({ username, type }) => {
          if (!currentChannels.some(
            channel => channel.type === type
              && channel.username === username,
          )) {
            currentChannels.push({ username, type });
          }
        });

        await companyQuery.set({
          ...currentCompany.data(),
          channels: currentChannels,
        });
      } catch (err) {
        errorHandler.logErrors(err);
      }
    },
  },
};

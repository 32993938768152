/* eslint-disable import/prefer-default-export */

import { state } from './state';
import { getters } from './getters';
import { mutations } from './mutations';

export const chat = {
  namespaced: true,
  state,
  getters,
  mutations,
};

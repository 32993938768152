/* eslint-disable import/prefer-default-export */

export const state = {
  token: null,
  user: {},
  conversations: {},
  messages: [],
  activeClientId: '',
  conversationStates: {},
};

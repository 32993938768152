
export function filesize(size) {
  let calculate = size;

  if (Number.isNaN(calculate)) {
    calculate = 0;
  }

  if (calculate < 1024) {
    return `${calculate}B`;
  }

  calculate /= 1024;

  if (calculate < 1024) {
    return `${calculate.toFixed(2)}KB`;
  }

  calculate /= 1024;

  if (calculate < 1024) {
    return `${calculate.toFixed(2)}MB`;
  }

  calculate /= 1024;

  return `${calculate.toFixed(2)}GB`;
}

export function truncate(str, length = 10) {
  if (!!str && str.length > length && (str.length - length) > 3) {
    const tailSize = Math.floor(length / 2);
    const pivot = str.length - tailSize;
    const strStart = str.substring(0, tailSize);
    const strEnd = str.substring(pivot);

    return `${strStart}...${strEnd}`;
  }
  return str;
}

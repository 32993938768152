/* eslint-disable import/prefer-default-export */
import Vue from 'vue';
import { getDataNotification } from '@/utils/helper';

export const notification = {
  namespaced: true,
  state: {
    toogleMenu: false,
    notifications: [],
    details: {},
    news: null,
  },
  getters: {
    getNotifications(state) {
      return state.notifications.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
      );
    },
    getDetails(state) {
      return state.details;
    },
    getNews(state) {
      let count = 0;
      state.news = count;
      state.notifications.forEach((item) => {
        if (!item.readAt) {
          count += 1;
          if (count > 9) {
            state.news = '+9';
          } else {
            state.news = count;
          }
        }
      });
      return state.news;
    },
  },
  mutations: {
    setToogleMenu(state, payload) {
      state.toogleMenu = payload;
    },
    setNotifications(state, payload) {
      const items = payload.map(item => getDataNotification(item));
      state.notifications = items;
    },
    markAsRead(state, payload) {
      let data = {};
      const index = state.notifications.findIndex(({ id }) => id === payload);
      if (index !== -1) {
        data = state.notifications[index];
        data = Object.assign({}, data, { readAt: Date.now() });
        Vue.set(state.notifications, index, data);
      }
    },
    setDetails(state, payload) {
      state.details = payload;
    },
  },
};

<template>
  <span
    class="dot"
    :class="[{ active }, size, color, customClass]"
    @click="$emit('click')"/>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'active',
    },
    size: {
      type: String,
      default: 'sm',
    },
    customClass: {
      type: [String, Object, Array],
    },
  },
};
</script>

<style scoped lang="scss">
@import "~styles/shared/status-dot.scss";
</style>

<template>
  <div class="textarea-container">
    <label v-if="label" class="textarea__label">{{ label }}</label>
    <div
      class="textarea__control"
      :class="[
        { error, disabled, 'read-only': readonly },
        customContainerClass,
      ]"
    >
      <textarea
        ref="hccTextarea"
        v-bind="$attrs"
        :name="name"
        :value="value"
        :cols="cols"
        :rows="rows"
        :readonly="readonly || disabled"
        :class="[{ disabled, resizable }, customClass]"
        v-on="{
          ...$listeners,
          input: (event) => $emit('input', event.target.value),
        }"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
    },
    value: {
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    cols: {
      type: Number,
      default: 50,
    },
    rows: {
      type: Number,
      default: 10,
    },
    resizable: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: [String, Object, Array],
    },
    customContainerClass: {
      type: [String, Object, Array],
    },
  },
  methods: {
    focus() {
      this.$refs.hccTextarea.focus();
    },
  },
};
</script>


<style scoped lang="scss">
@import "~styles/shared/textarea.scss";
</style>

import campaignsByCompanyIdQuery from '@/graphql/queries/campaigns/byCompanyId.gql';
import executeQuery from '@/utils/gql-api';

export default {
  async fetchCampaigns({ commit }, id) {
    const result = await executeQuery(
      'campaignsByCompanyId',
      campaignsByCompanyIdQuery,
      { companyId: id },
      false,
    );

    commit('setCampaigns', result);
  },
  updateCampaign({ commit, getters }, payload) {
    const campaigns = getters.getCampaigns;
    const index = campaigns.findIndex(c => c.id === payload.id);
    campaigns[index] = payload;
    commit('setCampaigns', campaigns);
    commit('setCurrentCampaign', payload);
  },
  selectCampaign({ commit, getters }, id) {
    const campaigns = getters.getCampaigns;
    const campaign = campaigns.find(c => c.id === id);
    localStorage.currentCampaign = JSON.stringify(campaign);
    commit('setCurrentCampaign', campaign);
  },
  addCampaign({ commit, getters }, payload) {
    const campaigns = getters.getCampaigns;
    campaigns.push(payload);
    commit('setCampaigns', campaigns);
  },
};

<template>
  <div
    class="badge"
    :class="[size, { rounded }, { circle }, { fill }, color, customClass]"
  >
    <slot name="badgeText">{{ text }}</slot>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: [String, Number],
    },
    size: {
      type: String,
      default: 'md',
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    circle: {
      type: Boolean,
      default: false,
    },
    fill: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
    },
    customClass: {
      type: [String, Object, Array],
    },
  },

};
</script>

<style lang="scss" scoped>
@import "~styles/shared/badges.scss";
</style>

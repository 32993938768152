import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';


// FIREBASE SELLIABOT CONFIG
const firebaseConfig = {
  apiKey: process.env.VUE_APP_SELLIABOT_API_KEY,
  authDomain: process.env.VUE_APP_SELLIABOT_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_SELLIABOT_PROJECT_ID,
  storageBucket: process.env.VUE_APP_SELLIABOT_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_SELLIABOT_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_SELLIABOT_APP_ID,

};

firebase.initializeApp(firebaseConfig, process.env.VUE_APP_SELLIABOT_PROJECT_ID);

// utils
export const auth = firebase.app(process.env.VUE_APP_SELLIABOT_PROJECT_ID).auth();
export const db = firebase.app(process.env.VUE_APP_SELLIABOT_PROJECT_ID).firestore();
export const storage = firebase.app(process.env.VUE_APP_SELLIABOT_PROJECT_ID).storage();

// export utils/refs
export default db;

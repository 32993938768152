<template>
  <div class="chat-box">
    <chat-box-header
      :title="title"
      :showBackButton="showFooter"
      :activeChat="activeChat"
      @go-back="back"
      @close="close"
      @minimize="minimize"
    />
    <div class="chat-box__body">
      <slot />
      <transition name="slide-footer">
        <chat-box-footer v-if="showFooter" @send="send" />
      </transition>
    </div>
  </div>
</template>


<script>
import ChatBoxHeader from './ChatBoxHeader.vue';
import ChatBoxFooter from './ChatBoxFooter.vue';

export default {
  components: {
    ChatBoxHeader,
    ChatBoxFooter,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    showFooter: {
      type: Boolean,
      default: false,
    },
    activeChat: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    back() {
      this.$emit('go-back');
    },
    minimize() {
      this.$emit('minimize');
    },
    send(message) {
      this.$emit('send-message', message);
    },

  },
};
</script>

<style scoped lang="scss">
@import "~styles/components/chatButton/_chat-button.scss";
</style>

import executeQuery from '@/utils/gql-api';
import listRoles from '@/graphql/queries/users/listRoles.gql';
import errorHandler from '@/utils/error.handler';

export default {
  fetchRoles({ commit }) {
    executeQuery('listRoles', listRoles)
      .then((result) => {
        commit('setRoles', result);
      }).catch(err => errorHandler.logErrors(err));
  },
};

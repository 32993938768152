<template>
  <div>
    <div class="agent-list__header">
      <div class="agent-list__header-search">
        <hcc-input
          v-model="search"
          placeholder="Search agents..."
          customClass="search"
          @input="searchValue"
        >
          <template #icon><magnify-icon /></template>
        </hcc-input>
        <div class="agent-list__buttons">
          <hcc-button-icon v-tooltip="'Filter'" @click="toggle">
            <filter-variant-icon />
          </hcc-button-icon>
          <hcc-button-icon
            v-tooltip="'Message all'"
            @click="selectAgent('all')"
          >
            <message-plus-icon />
          </hcc-button-icon>
        </div>
      </div>
      <div class="filter-wrapper">
        <div class="filter-content" ref="filterBox" v-if="isVisible">
          <hcc-select
            track-by="id"
            option-label="name"
            :placeholder="$t('filter.campaign')"
            :options="byAllCampaigns"
            custom-class="select"
            :value="currentCampaign"
            @select="onClose"
          />
        </div>
      </div>
    </div>
    <div class="agent-list__campaign">
      <hcc-badge fill size="lg" customClass="agent-list__badge-campaign">
        <template #badgeText>
          <span>{{ `${$t("filter.campaign")}:&nbsp;` }}</span>
          <span class="agent-list__badge-info"> {{ campaignName }}</span>
        </template>
      </hcc-badge>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import HccInput from '@/components/shared/HccInput/index.vue';
import HccButtonIcon from '@/components/shared/HccButtonIcon/index.vue';
import HccSelect from '@/components/shared/HccSelect/index.vue';
import HccBadge from '@/components/shared/HccBadge/index.vue';

export default {
  components: {
    HccInput,
    HccButtonIcon,
    HccSelect,
    HccBadge,
  },
  props: {
    selectedCampaign: {
      type: [Object, null],
    },
  },
  data() {
    return {
      search: '',
      currentCampaign: null,
      isVisible: false,
    };
  },
  computed: {
    ...mapGetters({
      company: 'shared/getCurrentCompany',
      campaign: 'setting/getCurrentCampaign',
    }),
    campaigns() {
      return this.company.campaigns;
    },
    campaignName() {
      return this.selectedCampaign ? this.selectedCampaign.name : this.campaign.name;
    },
    byAllCampaigns() {
      return [{ id: 'all', name: this.$t('chat-button.all') }].concat(this.campaigns);
    },
  },
  methods: {
    toggle() {
      this.isVisible = !this.isVisible;
    },
    onClose(campaign) {
      this.$emit('change-campaign', campaign);
      this.isVisible = false;
    },
    searchValue(value) {
      this.$emit('search', value);
    },
    selectAgent() {
      this.$emit('all-agents');
    },
  },
  mounted() {
    this.currentCampaign = this.selectedCampaign;
  },
};
</script>

<style scoped lang="scss">
@import "~styles/components/chatButton/_chat-button.scss";
</style>

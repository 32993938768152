import numeral from 'numeral';
import { db, storage } from '@/utils/firebase';
import { getDataImage } from '@/utils/helper';

export default {
  namespaced: true,
  state: {
    items: [],
    item: [],
    groupOptionsItems: [],
    currentGroup: [],
    idProduct: '',
  },
  mutations: {
    setItems(state, value) {
      state.items = value;
    },
    setItem(state, value) {
      state.item = value;
    },
    setGroupOptions(state, value) {
      state.groupOptionsItems = value;
    },
    setOptions(state, value) {
      state.currentGroup = value;
    },
    setIdProduct(state, value) {
      state.idProduct = value;
    },
  },
  actions: {
    async getItems({ commit }, id) {
      if (id) {
        const snap = await db.collection('products')
          .where('storeId', '==', id)
          .get();

        if (!snap.empty) {
          commit('setItems', snap.docs.map(doc => ({ id: doc.id, ...doc.data() })));
        } else {
          commit('setItems', []);
        }
      } else {
        commit('setItems', []);
      }
    },
    async getActiveItems({ commit }, id) {
      if (id) {
        const snap = await db.collection('products')
          .where('storeId', '==', id)
          .get();

        if (!snap.empty) {
          commit('setItems', snap.docs.map(doc => ({ id: doc.id, ...doc.data() })));
        } else {
          commit('setItems', []);
        }
      } else {
        commit('setItems', []);
      }
    },
    async getItemsOutOfStock({ commit }, id) {
      if (id) {
        let stock = 0;
        const snapShop = await db.collection('stores')
          .doc(id)
          .get();

        if (snapShop) {
          const shop = snapShop.data();
          stock = shop.settings.minStock;
        }

        const snap = await db.collection('products')
          .where('storeId', '==', id)
          .where('stock', '<', stock)
          .get();

        if (!snap.empty) {
          commit('setItems', snap.docs.map(doc => ({ id: doc.id, ...doc.data() })));
        } else {
          commit('setItems', []);
        }
      } else {
        commit('setItems', []);
      }
    },
    async getById({ commit }, id) {
      const snap = await db.collection('products')
        .doc(id)
        .get();
      if (snap) {
        const image = await getDataImage({ ...snap.data() });
        commit('setItem', { ...snap.data(), image });
      } else {
        commit('setItem', null);
      }
    },
    async remove(module, id) {
      await db.collection('products')
        .doc(id)
        .delete();
    },
    async duplicate({ dispatch }, params) {
      const { id, ...newStore } = params;
      const data = { ...newStore };
      await db.collection('products')
        .add(data);
      dispatch('getItems', data.storeId);
    },
    async save({ dispatch, commit }, params) {
      const { id, file, ...newStore } = params;
      let lastId = id;
      const data = { ...newStore };
      const snapProducts = await db.collection('products')
        .doc(id)
        .get();
      if (!snapProducts.data()) {
        const snap = await db.collection('products')
          .add(data);
        lastId = snap.id;
      }
      if (file) {
        const storageRef = storage.ref();
        const spaceRef = storageRef.child(`${data.storeId}/images/${lastId}/perfil.jpg`);
        const snapshot = await spaceRef.put(file);
        const imageURL = await snapshot.ref.getDownloadURL();
        data.imageURL = imageURL;
      }
      data.lastModification = new Date();
      if (data.categoria.id === 'Producto') {
        data.status = true;
        data.stock = numeral(data.stock).value();
      }
      await db.collection('products')
        .doc(lastId)
        .update(data);
      commit('setIdProduct', lastId);
      dispatch('getItems', data.storeId);
    },
    async saveGroupOptions({ dispatch }, params) {
      const { id, newGroup: data } = params;
      const product = db.collection('products')
        .doc(id);
      if (data.id) {
        await product.collection('optionsGroup')
          .doc(data.id)
          .update(data);
      } else {
        await product.collection('optionsGroup')
          .add(data);
      }
      dispatch('getGroupOption', id);
    },
    async removeGroup({ dispatch }, params) {
      const { id, idProducto } = params;
      await db.collection('products')
        .doc(idProducto)
        .collection('optionsGroup')
        .doc(id)
        .delete();
      dispatch('getGroupOption', idProducto);
    },
    async getGroupOption({ commit }, idProducto) {
      const subCollections = [];
      const querySnapshot = await db.collection('products')
        .doc(idProducto)
        .collection('optionsGroup')
        .get();
      querySnapshot.forEach((doc) => {
        subCollections.push({
          id: doc.id,
          idProducto,
          ...doc.data(),
        });
      });
      commit('setGroupOptions', subCollections);
    },
    async getGroupOptionById({ commit }, { idProduct, idGroup }) {
      const doc = await db.collection('products')
        .doc(idProduct)
        .collection('optionsGroup')
        .doc(idGroup)
        .get();

      if (doc.exists) {
        commit('setOptions', doc.data());
      } else {
        commit('setOptions', {});
      }
    },
  },
};

<template>
  <div v-if="message.text" :class="messageClass">
    <span>{{ message.text }}</span>
    <span class="messages__timestamp">{{ timeStamp }}</span>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: Object,
      required: true,
    },
    received: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    messageClass() {
      return this.received ? 'messages__received' : 'messages__sent';
    },
    timeStamp() {
      return this.message.createdAt ? this.$moment(this.message.createdAt).local().format('HH:mm A') : null;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~styles/components/chatButton/_chat-button.scss";
</style>

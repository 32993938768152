<template>
  <div class="chat-box__header">
    <button v-if="showBackButton" class="chat-box__header-button" @click="back">
      <arrow-left-icon class="chat-box__header-back-icon" />
    </button>
    <div v-else class="chat-box__header-status">
      <hcc-status-dot size="sm" />
    </div>
    <span class="chat-box__header-title">
      {{ title }}
    </span>
    <div class="chat-box__header__icons">
      <button
        v-show="activeChat"
        class="chat-box__header-button"
        @click="minimize"
      >
        <window-minimize-icon class="chat-box__header-minimize-icon" />
      </button>
      <button class="chat-box__header-button" @click="close">
        <close-circle-icon class="chat-box__header-delete-icon" />
      </button>
    </div>
    <hcc-confirmation />
  </div>
</template>

<script>
import HccConfirmation from '@/components/shared/HccConfirmation/index.vue';
import HccStatusDot from '@/components/shared/HccStatusDot/index.vue';


export default {
  components: {
    HccConfirmation,
    HccStatusDot,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    showBackButton: {
      type: Boolean,
      default: false,
    },
    activeChat: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    close() {
      if (!this.activeChat) {
        this.$emit('close');
      } else {
        this.$modal.show('confirmation', {
          title: this.$t('chat-button.close'),
          description: this.$t('chat-button.confirm-close'),
          confirm: () => this.$emit('close'),
        });
      }
    },
    back() {
      this.$emit('go-back');
    },
    minimize() {
      this.$emit('minimize');
    },
  },
};
</script>

<style scoped lang="scss">
@import "~styles/components/chatButton/_chat-button.scss";
</style>

<template>
  <div class="agent-chat" ref="chat">
    <div class="agent-chat__content">
      <span class="agent-chat__today" v-if="messages.length > 0"
        >{{ $t("chat-button.today") }}
      </span>
      <div
        v-for="message in messages"
        :key="message.id"
        class="agent-chat__messages"
      >
        <chat-message :message="message" :received="message.received" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ChatMessage from './ChatMessage.vue';

export default {
  props: {
    agent: {
      required: true,
      type: Object,
    },
  },
  components: {
    ChatMessage,
  },
  computed: {
    ...mapGetters({ agentMessages: 'internalChat/agentMessages' }),
    messages() {
      return this.agentMessages(this.agent.id);
    },
  },
  watch: {
    messages(newValue) {
      if (newValue) {
        this.scrollToBottom();
      }
    },
  },
  methods: {
    scrollToBottom() {
      const container = this.$refs.chat;
      container.scrollTop = container.scrollHeight;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~styles/components/chatButton/_chat-button.scss";
.div {
  z-index: 10;
  background-color: $black;
  opacity: 0.1;
  position: absolute;
  left: 8px;
  right: 10px;
  top: 6px;
  height: 430px;
  width: 430px;
}
</style>
